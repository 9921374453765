import type { IHttpClient } from '@wix/yoshi-flow-editor';
import type { CursorQuery } from '@wix/ambassador-restaurants-menus-v1-item-variant/types';
import { getChunksByFilterOperatorLimit } from './utils/chunksDivider';
import { MAX_FILTER_OPERATOR_LENGTH } from './consts';
import type { PriceVariant } from 'root/types/priceVariants';
import { PopulatedItemClientFactory } from '@wix/restaurants-populated-item-client/web';

export class PopulatedVariantsClient {
  httpClient;
  populatedVariantsClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
    this.populatedVariantsClient = PopulatedItemClientFactory({
      httpClient,
      populationLevel: {
        labels: false,
        modifierGroups: false,
        modifiers: false,
        priceVariants: false,
      },
    }).variantsClient;
  }

  queryAllPriceVariants = async (priceVariantsIds?: string[]) => {
    try {
      const priceVariantsIdsChunks: string[][] | undefined = getChunksByFilterOperatorLimit(
        MAX_FILTER_OPERATOR_LENGTH,
        priceVariantsIds
      );

      let query: CursorQuery = {
        filter: {
          $or: priceVariantsIdsChunks?.map((priceVariantIdsChunk) => ({
            id: {
              $in: priceVariantIdsChunk,
            },
          })),
        },
      };

      const allPriceVariants: PriceVariant[] = [];
      let hasNext = true;

      while (hasNext) {
        const response = await this.populatedVariantsClient.query(query);

        allPriceVariants.push(...(response.data as PriceVariant[]));

        hasNext = !!response.pagingMetadata?.hasNext;
        query = {
          cursorPaging: {
            cursor: response.pagingMetadata?.cursors?.next,
          },
        };
      }

      return allPriceVariants;
    } catch (e: unknown) {
      console.error(e);
      return [];
    }
  };

  getVariantById = async (variantId: string) => {
    try {
      return this.populatedVariantsClient.getById(variantId).then((response) => response);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };
}
