import model from './model';
import { FulfillmentPickerController } from './FulfillmentPickerController';
import type { TFunction } from '@wix/yoshi-flow-editor';
import type { FulfillmentPickerProps } from '../../types/widgetsProps';

export default model.createController(({ $w, $widget, flowAPI, $bindAll, $bind }) => {
  const t = flowAPI.translations.t as TFunction;
  const fulfillmentPickerController = new FulfillmentPickerController($w, $bindAll, $bind);

  let didSetOnClickHandlers = false;

  const setOnClickHandlersIfNeeded = (props: FulfillmentPickerProps) => {
    if (!didSetOnClickHandlers && props.onDispatchTypeChange) {
      fulfillmentPickerController.initButtons(props);
      didSetOnClickHandlers = true;
    }
  };

  $widget.onPropsChanged((_, nextProps) => {
    fulfillmentPickerController.setDispatchState(nextProps.data);
    fulfillmentPickerController.initFulfillmentPicker(t, nextProps.data.configuredDispatchTypes);
    setOnClickHandlersIfNeeded(nextProps.data);
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const { data } = $widget.props;
      fulfillmentPickerController.initFulfillmentPicker(t, data.configuredDispatchTypes);
      fulfillmentPickerController.setDispatchState(data);
      setOnClickHandlersIfNeeded(data);
    },
    exports: {},
  };
});
