import type { PriceVariant, PriceVariantTpa } from 'root/types/priceVariants';

export const convertPriceVariantsToPriceVariantsType = ({
  priceVariants,
}: {
  priceVariants: PriceVariantTpa[];
}): PriceVariant[] => {
  const updatedPriceVariants: PriceVariant[] = [];
  priceVariants.forEach((priceVariant) => {
    if (priceVariant.id) {
      updatedPriceVariants.push({
        id: priceVariant.id,
        name: priceVariant.name ?? '',
      });
    }
  });

  return updatedPriceVariants;
};
