import model from './model';
import { SectionsController } from './sectionsController';
import { hasDataChanged } from '../../utils/utils';
import type { SectionWidgetProps } from '../../types/menusTypes';
import type { TFunction } from '@wix/yoshi-flow-editor';

export default model.createController(({ $w, $bind, $widget, flowAPI }) => {
  const t = flowAPI.translations.t as TFunction;
  const sectionsController = new SectionsController($bind, $w, t);
  $widget.onPropsChanged((prevProps, nextProps) => {
    const shouldInit = hasDataChanged({
      prevData: prevProps.data?.sections,
      nextData: nextProps.data?.sections,
    });

    if (shouldInit) {
      const { sections, menuId }: SectionWidgetProps = nextProps.data;
      sectionsController.init(sections, menuId);
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const { sections, menuId }: SectionWidgetProps = $widget.props.data;
      sectionsController.init(sections, menuId);
    },
    exports: {},
  };
});
