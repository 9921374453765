import type { IHttpClient } from '@wix/yoshi-flow-editor';
import { listLabels, getLabel } from '@wix/ambassador-restaurants-menus-v1-item-label/http';

export class LabelsClient {
  httpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  fetchAllLabels = async () => {
    try {
      return this.httpClient.request(listLabels({})).then((response) => response.data);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };

  getLabelById = async (labelId: string) => {
    try {
      return this.httpClient.request(getLabel({ labelId })).then((response) => response.data);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };
}
