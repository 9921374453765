import type { OLOWidgetState } from '../types/businessTypes';

const PUBSUB_EVENT = {
  SCROLL_TO_MENU: 'onScrollToMenu',
  SCROLL_TO_SECTION: 'onScrollToSection',
  SECTION_VIEWPORT_ENTER: 'onSectionViewportEnter',
  SECTION_VIEWPORT_LEAVE: 'onSectionViewportLeave',
  HEADER_VIEWPORT_ENTER: 'onHeaderViewportEnter',
  HEADER_VIEWPORT_LEAVE: 'onHeaderViewportLeave',
  FETCH_FAILED: 'onFetchFailed',
  MENUS_DATA_FETCH_FAILED: 'onMenusDataFetchFailed',
} as const;

type PubsubEvent = (typeof PUBSUB_EVENT)[keyof typeof PUBSUB_EVENT];

type PubsubCallback = {
  [PUBSUB_EVENT.SCROLL_TO_MENU]: (data: { menuId: string }) => void;
  [PUBSUB_EVENT.SCROLL_TO_SECTION]: (data: { sectionId: string }) => void;
  [PUBSUB_EVENT.SECTION_VIEWPORT_ENTER]: (data: { sectionId: string }) => void;
  [PUBSUB_EVENT.SECTION_VIEWPORT_LEAVE]: (data: { sectionId: string }) => void;
  [PUBSUB_EVENT.HEADER_VIEWPORT_ENTER]: () => void;
  [PUBSUB_EVENT.HEADER_VIEWPORT_LEAVE]: () => void;
  [PUBSUB_EVENT.FETCH_FAILED]: (data: { oloState: OLOWidgetState }) => void;
  [PUBSUB_EVENT.MENUS_DATA_FETCH_FAILED]: (data: { shouldShowErrorContent?: boolean }) => void;
};

type Subscribers = Record<PubsubEvent, PubsubCallback[PubsubEvent][]>;

export class Pubsub {
  private subscribers: Subscribers;

  constructor() {
    this.subscribers = {} as Subscribers;
  }

  subscribe<T extends PubsubEvent, K extends PubsubCallback[T]>(event: T, callback: K) {
    if (!this.subscribers[event]) {
      this.subscribers[event] = [];
    }

    this.subscribers[event].push(callback);
  }

  publish<T extends PubsubEvent, K extends Parameters<PubsubCallback[T]>[0]>(event: T, data?: K) {
    if (!this.subscribers[event]) {
      return;
    }

    // @ts-expect-error
    this.subscribers[event].forEach((callback) => callback(data));
  }
}
