export interface IPersistStorageService {
  setItem: <T>(key: string, value: T) => void;
  getItem: <T>(key: string) => T | undefined;
}

export const PersistStorageService = (storage: {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
}): IPersistStorageService => {
  const MILLISECONDS_IN_SECOND = 1000;
  const SECONDS_IN_MINUTE = 60;
  const MINUTES_TO_EXPIRED = 10;
  const returnObject = {
    setItem: <T>(key: string, value: T) => {
      try {
        const hourInMilliseconds = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_TO_EXPIRED;
        const valueWithExpiry = {
          value,
          expiry: Date.now() + hourInMilliseconds,
        };
        storage.setItem(key, JSON.stringify(valueWithExpiry));
      } catch (e) {
        console.error('PersistStorageService setItem error', e);
      }
    },
    getItem: <T>(key: string) => {
      try {
        const serializedItem = storage.getItem(key);
        const item = serializedItem && JSON.parse(serializedItem);
        if (item?.expiry >= Date.now()) {
          return item?.value as T;
        }
      } catch (e) {
        console.error('PersistStorageService getItem error', e);
      }
    },
  };

  return returnObject;
};
