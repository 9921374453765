import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';
import type { HeaderWidgetProps } from '../../types/menusTypes';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/

const DEFAULT_DATA: HeaderWidgetProps = {
  headerTitle: '',
  headerDescription: '',
};

export default createBlocksModel({
  widgetName: 'Header',
  props: {
    // Here you can define your widget props
    data: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: {} as any,
      defaultValue: DEFAULT_DATA,
    },
    operation: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: {} as any,
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
