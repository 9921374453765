import type { IHttpClient } from '@wix/yoshi-flow-editor';
import { getMenu, listMenus } from '@wix/ambassador-restaurants-menus-v1-menu/http';
import type { MenuTpa } from '../types/menusTypes';

export class MenusClient {
  httpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  fetchListVisibleMenus = async (menuIds: string[] = []): Promise<{ menus: MenuTpa[] }> => {
    try {
      return this.httpClient.request(listMenus({ menuIds })).then((res) => {
        const {
          data: { menus = [] },
        } = res;

        return {
          menus: menus.filter((menu) => menu.visible) as MenuTpa[],
        };
      });
    } catch (e: unknown) {
      console.error(e);
      return { menus: [] };
    }
  };

  getMenuById = async (menuId: string) => {
    try {
      return this.httpClient.request(getMenu({ menuId })).then((response) => response.data);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };
}
