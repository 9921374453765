import type { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  listFirstAvailableTimeSlotForFulfillmentTypes,
  listAvailableDatesInRange,
  listAvailableTimeSlotsForDate,
} from '@wix/ambassador-restaurants-operations-v1-operation/http';
import { queryFulfillmentMethods } from '@wix/ambassador-restaurants-v1-fulfillment-method/http';
import type { Address, DispatchType } from '../types/businessTypes';
import { state } from '../states/RootState';
import { DateTime } from 'luxon';
import { convertDate } from './utils/utils';
import { processFulfillmentTimeSlot, mergeASAPTimeSlots } from './utils/TimeSlotProcessor';

export class FulfillmentsClient {
  constructor(private httpClient: IHttpClient, private operationId: string) {}

  fetchAllFulfillments = async (fulfillmentIds?: string[]) => {
    try {
      const data = await this.httpClient
        .request(
          queryFulfillmentMethods({
            query: {
              filter: {
                $and: [
                  {
                    id: {
                      $in: fulfillmentIds,
                    },
                  },
                ],
              },
            },
          })
        )
        .then((response) => response.data);

      return data?.fulfillmentMethods ?? [];
    } catch (e: unknown) {
      state.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
      return [];
    }
  };

  fetchFirstAvailableTimeSlot = async (deliveryAddress?: Address) => {
    const data = await this.httpClient
      .request(
        listFirstAvailableTimeSlotForFulfillmentTypes({
          operationId: this.operationId,
          deliveryAddress,
        })
      )
      .then((response) => response.data);

    return data?.timeSlots?.map(processFulfillmentTimeSlot) ?? [];
  };

  fetchAvailableDatesInRange = async ({
    deliveryAddress,
    dispatchType,
    from,
    until,
    timezone,
  }: {
    deliveryAddress?: Address;
    dispatchType: DispatchType;
    from: Date;
    until: Date;
    timezone: string;
  }) => {
    const data = await this.httpClient
      .request(
        listAvailableDatesInRange({
          operationId: this.operationId,
          deliveryAddress,
          from: convertDate(from),
          until: convertDate(until),
        })
      )
      .then((response) => response.data);

    return (
      data?.availableDates
        ?.filter(
          ({ fulfilmentType }) => (fulfilmentType as unknown as DispatchType) === dispatchType
        )
        .map(
          ({ dates }) =>
            dates?.map(({ year, month, day }) =>
              DateTime.fromObject(
                { year: year as number, month: month as number, day: day as number },
                {
                  zone: timezone,
                }
              )
            ) ?? []
        )
        .flat() ?? []
    );
  };

  fetchAvailableTimeSlotsForDate = async ({
    deliveryAddress,
    dispatchType,
    date,
  }: {
    deliveryAddress?: Address;
    dispatchType: DispatchType;
    date: Date;
  }) => {
    const data = await this.httpClient
      .request(
        listAvailableTimeSlotsForDate({
          operationId: this.operationId,
          deliveryAddress,
          date: convertDate(date),
        })
      )
      .then((response) => response.data);

    const timeSlotsByDispatchType = (data?.timeSlots ?? []).filter(
      (slot) => (slot.fulfilmentType as unknown as DispatchType) === dispatchType
    );

    return mergeASAPTimeSlots(timeSlotsByDispatchType).map((slot) =>
      processFulfillmentTimeSlot(slot)
    );
  };
}
