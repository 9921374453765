import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { convertPriceVariantsToPriceVariantsType } from '../utils/priceVariantsConvertUtils';
import { doesArrayDataExist } from 'root/utils/utils';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { getMonitoredApiCall } from './utils/getMonitoredApiCall';
import { PopulatedVariantsClient } from './priceVariantsClient';
import type { PriceVariant } from 'root/types/priceVariants';

export type PriceVariantsState = {
  priceVariants: PriceVariant[];
};

export const PopulatedPriceVariantsClient = (
  flowAPI: PlatformControllerFlowAPI,
  fedops?: FedopsLogger
) => {
  const priceVariantsState: PriceVariantsState = {
    priceVariants: [],
  };
  const priceVariantsClient = new PopulatedVariantsClient(flowAPI.httpClient);

  return {
    getAll: async ({ priceVariantsIds }: { priceVariantsIds?: string[] }) => {
      const priceVariantIds: string[] = [];
      const priceVariants: PriceVariant[] = [];
      if (doesArrayDataExist(priceVariantsIds)) {
        priceVariantIds.push(...(priceVariantsIds || []));
      }

      const seenPriceVariantIds: Record<string, boolean> = {};
      const priceVariantIdsWithoutDuplications = priceVariantIds.filter((priceVariantId) => {
        if (seenPriceVariantIds[priceVariantId]) {
          return false;
        } else {
          seenPriceVariantIds[priceVariantId] = true;
          return true;
        }
      });

      if (doesArrayDataExist(priceVariantIdsWithoutDuplications)) {
        const priceVariantsResponse = await getMonitoredApiCall({
          callback: () =>
            priceVariantsClient.queryAllPriceVariants(priceVariantIdsWithoutDuplications),
          fedops: {
            start: fedops?.fetchAllModifiersStarted,
            end: fedops?.fetchAllModifiersEnded,
          },
          reportError: flowAPI.reportError,
        });
        priceVariants.push(
          ...convertPriceVariantsToPriceVariantsType({
            priceVariants: priceVariantsResponse.data || [],
          })
        );
      }

      priceVariantsState.priceVariants = priceVariants;
      return priceVariantsState;
    },
  };
};
