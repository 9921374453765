import type model from './model';
import { OLO_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import type { PopulatedMenu } from '../../types/menusTypes';
import type { I$W, TFunction } from '@wix/yoshi-flow-editor';
import type { OLOWidgetState, Operation } from '../../types/businessTypes';
import { state } from '../../states/RootState';
import type { ControllerParams } from 'root/types/widgets';

type Bind = ControllerParams<typeof model>['$bind'];

export class OLOController {
  constructor(private $bind: Bind, private $w: I$W, private t: TFunction) {
    state.pubsub.subscribe('onFetchFailed', this.switchState);
  }

  setMenus(menus: PopulatedMenu[]) {
    this.$bind(OLO_WIDGET_COMPONENT_IDS.menuContainer, {
      data: () => ({ menus }),
    });
  }

  setHeader(operation: Operation) {
    this.$bind(OLO_WIDGET_COMPONENT_IDS.header, {
      operation: () => operation,
    });
  }

  setNavigationMenu(menus: PopulatedMenu[]) {
    const hasAtLeastOneMenuWithSections = menus.some((menu) => menu.sectionIds?.length > 0);

    this.$bind(OLO_WIDGET_COMPONENT_IDS.navigationMenu, {
      data: () => ({ menus }),
      collapsed: () => !hasAtLeastOneMenuWithSections,
    });
  }

  switchState = ({ oloState }: { oloState: OLOWidgetState }) => {
    const multiStateBox = this.$w(OLO_WIDGET_COMPONENT_IDS.oloMultiStateBox);
    multiStateBox.changeState(oloState);

    this.$bind(OLO_WIDGET_COMPONENT_IDS.errorStateTitle, {
      text: () => this.t('menu_olo.errorState.title'),
    });

    this.$bind(OLO_WIDGET_COMPONENT_IDS.errorStateSubtitle, {
      text: () => this.t('menu_olo.errorState.subTitle'),
    });
  };
}
