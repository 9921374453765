export const getChunksByFilterOperatorLimit = (operatorLimit: number, idsArray?: string[]) => {
  return idsArray?.reduce((resultArray: string[][], id, idx) => {
    const chunkIndex = Math.floor(idx / operatorLimit);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(id);

    return resultArray;
  }, []);
};
